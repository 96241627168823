import { extendsObj } from "./extendsObj";
import Swal from "sweetalert2";
import ModalFactory from "./ModalFactory";

type Options = {
  method?: string;
  headers?: Headers;
  body?: BodyInit | null;
  mode?: RequestMode;
  credentials?: RequestCredentials;
  cache?: RequestCache;
  redirect?: RequestRedirect;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
  integrity?: string;
  keepalive?: boolean;
  signal?: AbortSignal | null;
}

type FetchSetup = {
  url: string;
  callback: Function;
  options?: Options;
  btnSend?: HTMLButtonElement;
}

const fetchSetup = (() => {

  let defaultOption = {
    //...
  }

  return async ({url, callback, options = {}, btnSend = null}: FetchSetup) => {

    let initOptions = extendsObj(defaultOption, options);

    if (options.body instanceof FormData) {
      initOptions.body = options.body;
    }

    const response = await fetch(url, initOptions);

    if (btnSend) {
      btnSend.disabled = false;
    }

    if (response.status === 500) {

      const text = await response.text();

      if (text.includes('dump')) {
        const modalId = 'modal-dd';
        const modalDD = document.querySelector(`#${modalId}`);

        let page = document.createElement('html');
        page.innerHTML = text;
        page.querySelectorAll('a').forEach((a) => {
          a.setAttribute('target', '_top');
        });

        let iframe = document.createElement('iframe');
        iframe.style.backgroundColor = '#17161A'
        iframe.style.borderRadius = '5px'
        iframe.style.width = '100%'
        iframe.style.height = '100%'

        const modalContent = modalDD.querySelector('#modal-dd-content');
        modalContent.innerHTML = '';
        modalContent.appendChild(iframe);

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(page.outerHTML);
        iframe.contentWindow.document.close();

        ModalFactory.set(modalId, true);
        throw new Error('dd');
      }

    }

    const data = await response.json();

    if (response.status === 422) {
      const {errors} = data;

      let errorsList = '<ul>';
      for (let error in errors) {
        errorsList += `<li class="cbf-bg-red-50 cbf-border-l-4 cbf-border-red-900 cbf-mb-2 cbf-font-bold cbf-text-red-900 cbf-text-sm cbf-p-2 cbf-text-left">
                        ${errors[error]}
                      </li>`;
      }
      errorsList += '</ul>';

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: errorsList,
        timer: 5000,
      });

      throw new Error(`Error: ${data.message}`);
    }

    callback(data);

    return data;

  }

})();

export default fetchSetup;